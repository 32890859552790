<template>
  <header class="topbar">
    <b-navbar
      toggleable="md"
      :type="`${navbarColor == 'white' ? 'light' : 'dark'}`"
      class="p-0"
      :variant="navbarColor"
    >
      <div :class="`navbar-header d-block d-lg-none pl-3`">
        <LogoDark v-if="navbarColor == 'white'" />
        <LogoLight v-else />
      </div>

      <b-navbar-nav type="light" class="d-block ml-md-2 ">
        {{ name }}
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item @click="showMobileSidebar" class="d-block d-lg-none"
          ><i
            class="mdi mdi-menu fs-3"
            style="font-size: 100px"
          ></i></b-nav-item
      ></b-navbar-nav>
      <!-- <b-navbar-toggle target="nav-collapse" class="mx-2 border-0"
        ><i class="mdi mdi-dots-horizontal"></i
      ></b-navbar-toggle> -->
      <b-collapse id="nav-collapse" is-nav class="">
        <!-- -----------------------------------------------------------
          Right aligned nav items
        ----------------------------------------------------------- -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right no-caret>
            <template #button-content>
              <i class="mdi mdi-account-box"></i>
            </template>
            <b-dropdown-item href="/logout">ออกจากระบบ</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import { mapState } from "vuex";
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";

export default {
  name: "vertical-header",
  data: () => ({
    showToggle: false,
    name: "",
  }),
  components: {
    LogoDark,
    LogoLight,
  },
  computed: {
    ...mapState(["navbarColor", "logoColor", "LayoutType"]),
  },
  methods: {
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", true);
    },
  },
  mounted() {
    this.name = window.localStorage.getItem("saleName");
  },
};
</script>
